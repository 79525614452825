import { Link, graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Img from "gatsby-image"
import styled from 'styled-components';
import { layout, position, typography } from 'styled-system';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import { Flex , Box, FlexHome} from '../elements';

const H1 = styled.h1`
  color: white;
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 140px;
  font-family: 'Arial-Black', 'Arial', sans-serif;
  margin-bottom: 120px;
  ${typography}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 0px 15px 10px 15px;
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 150px;
  font-family: 'Arial-Black', 'Arial', sans-serif;
  color: #979797;
  position: relative;
  &:hover, &:active {
    color: #F07D00;
  }
  ${position}
  ${layout}
  ${typography}
`;

const StyledLinkWithSeparator = styled(StyledLink)`
  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -10px;
    width: calc(100% - 30px);
    height: 3px;
    background-color: #979797;
  }
`;



function IndexPage ({ location }) {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "Home_Zoe_OUVRIER.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout location={location} header={false}>
      <SEO />
      <Box display={[ 'none', 'none', 'block', 'block' ]} width="100%" height="100%">
        
            
            <FlexHome >
              <Link to="/artworks">        
                <Img
                      fluid={data.desktop.childImageSharp.fluid}
                      alt="A corgi smiling happily"
                      style={{position:"static" }}
                    />
              </Link>
            </FlexHome>
      
        
      </Box>  
      

      <Box display={[ 'block', 'block', 'none', 'none' ]} width="100%" height="100%">
        <Flex flexDirection="column" height="100%" alignItems="center" justifyContent="center" >
        <H1 fontSize={['52px', '52px', '80px', '90px', '132px']}>ZOE OUVRIER</H1>
        <StyledLink
          fontSize={['52px', '52px', '80px', '90px', '132px']}
          to="/artworks"
          display="block"
        >
           Artworks
        </StyledLink>
        </Flex>
      </Box>
      
      
    </Layout>
  );
};

export default IndexPage;
